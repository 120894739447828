export const isEDMode =
  typeof window !== "undefined"
    ? window.location.href.indexOf("ed=1") > 0
    : false;

export const getUrlParameter = (param) => {
  param = param.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + param + "=([^&#]*)");
  const results = regex.exec(window.location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const showCookiePreference = (e) => {
  e.preventDefault();
  const floatingButton = document.querySelector(
    "#ot-sdk-btn-floating .ot-floating-button__front .ot-floating-button__open"
  );
  if (floatingButton) {
    floatingButton.click();
  }
};

export const createTValue = (params) => {
  // Check if there are no parameters and return 'organic'
  if (!params.toString()) return "organic";

  // Check for utm parameters
  const taxonomyKeys = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ];
  const hasUtmParams = taxonomyKeys.some((key) => params.has(key));

  // If utm parameters are present, map them to a formatted string
  if (hasUtmParams) {
    return taxonomyKeys
      .map((key) => params.get(key) || "")
      .join("_")
      .replace(/\|+$/, "");
  }

  // If no utm parameters, check for specific other parameters
  if (params.has("fbclid")) return "social";
  if (params.has("gclid")) return "cpc";
  if (params.has("dclid") || params.has("_hsenc")) return "display";

  return "organic";
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const isBrowser = typeof window !== "undefined";

export const isProd = () => {
  if (typeof window !== "undefined") {
    return window.location.hostname.includes('sofdra.com')
  }
  return false
}
