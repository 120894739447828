import React, { createContext, useEffect, useRef, useState } from "react";
import { createTValue, getCookie } from "@utils/utils";
import { exitModalTagging } from "@utils/utils.gtm";
import useOptanonConsent from "@hooks/useOptanonConsent";

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  const headerRef = useRef(null);
  const stickyCtaRef = useRef(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [scrollDirection, setScrollDirection] = useState("up");

  const setHeaderVisible = (visible) => {
    setIsHeaderVisible(visible);
  };

  const updateScrollDirection = (direction) => {
    setScrollDirection(direction);
  };

  useEffect(() => {
    const storeUrlParamsInCookie = () => {

      const cookieValue = getCookie("t");
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.toString() || !cookieValue) {
        const urlParameters = createTValue(searchParams);
        document.cookie = `t=${urlParameters}; path=/; SameSite=Lax`;
      }
    };

    storeUrlParamsInCookie();
  }, []);

  // State for ExitModal
  const [showExitModal, setShowExitModal] = useState(false);
  const [exitUrl, setExitUrl] = useState("");

  // Functions to control ExitModal
  const openExitModal = (url) => {
    setExitUrl(url);
    setTimeout(() => {
      setShowExitModal(true);
    }, 100);
  };

  const closeExitModal = (linkText) => {
    setShowExitModal(false);
    const eventAction = typeof linkText === "string" ? linkText : "Close";
    exitModalTagging(eventAction, exitUrl);
    setExitUrl("");
  };

  const store = {
    headerRef,
    stickyCtaRef,
    showExitModal,
    exitUrl,
    openExitModal,
    closeExitModal,
    isHeaderVisible,
    setHeaderVisible,
    scrollDirection,
    updateScrollDirection,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;
export { AppProvider };
