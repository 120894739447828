import React from "react";
import { AppProvider } from "@context/AppContext";

// Global styles
import "./src/styles/global.css";
import "./src/styles/cookies.css";

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
