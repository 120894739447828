import { useState, useEffect } from "react";

const useOptanonConsent = () => {
  const [consentGiven, setConsentGiven] = useState(null);

  const checkConsent = () => {
    if (typeof window !== "undefined" && window.OnetrustActiveGroups) {
      const activeGroups = window.OnetrustActiveGroups || "";
      const hasConsent = activeGroups.includes("C0007"); // Always check for C0007
      setConsentGiven(hasConsent);
    }
  };

  useEffect(() => {
    checkConsent(); // Initial check

    // Listen for OneTrust consent changes
    const handleConsentChange = () => {
      checkConsent();
    };

    window.addEventListener("OneTrustGroupsUpdated", handleConsentChange);

    return () => {
      window.removeEventListener("OneTrustGroupsUpdated", handleConsentChange);
    };
  }, []);

  return consentGiven;
};

export default useOptanonConsent;
