const logTracking = (eventTitle, cat, act, lab) => {
  console.log("%c event: ", "color: red", eventTitle);
  console.log("%c category: ", "color: red", cat);
  console.log("%c action: ", "color: red", act);
  console.log("%c label: ", "color: red", lab);
  console.log("%c -----------: ", "color: blue");
};

export const gtmTrack = (
  eventTitle,
  eventCategory,
  eventAction,
  eventLabel
) => {
  logTracking(eventTitle, eventCategory, eventAction, eventLabel);

  window.dataLayer.push({
    event: eventTitle,
    eventCategory,
    eventAction,
    eventLabel,
  });
};

export const gtmSubmit = (formName) => {
  //2.8
  // Category – Form
  // Action – $Form Name - $Button Text (or Submit)
  // Label –  (No Label needed assuming the following success page has a unique success URL, tracked as a GA pageview.)
  gtmTrack("formSubmit", "Form Submissions", formName, "Complete");
};

export const gtmTrackFormInteractions = (formName, formClass) => {
  // Text Form Field
  // Category: Form
  // Action: [Form Name] - [Field Name]
  // Label: Interaction
  // **No PII collected. No health information collected, value will be blank.

  const form = document.querySelector("." + formClass);

  const onFieldInteract = (input) => {
    const fieldName = input.name;
    const fieldValue = input.type === "checkbox" ? input.checked : input.value; // check boxes use boolean value, other inputs use actual
    if (fieldName && fieldValue) {
      const eventLabel =
        input.type !== "text" && input.type !== "email"
          ? fieldName + " " + fieldValue
          : fieldName; // do not include personal data
      gtmTrack(
        "formInteraction",
        "Form Field Completion",
        formName,
        eventLabel
      );
    }
  };

  if (form) {
    const el_inputs = form.querySelectorAll(
      "input[type=text], input[type=email], input[type=radio], input[type=checkbox], input[type=file], textarea, select"
    );

    el_inputs.forEach((input) => {
      input.addEventListener("blur", () => onFieldInteract(input), true);
    });
  }
};

export const gtmTrackFieldsOnSubmit = (formName, formClass) => {
  const form = document.querySelector("." + formClass);

  const onFieldInteract = (input) => {
    const fieldName = input.name;
    const fieldValue = input.type === "checkbox" ? input.checked : input.value; // check boxes use boolean value, other inputs use actual
    if (fieldName && fieldValue) {
      const eventLabel =
        input.type !== "text" && input.type !== "email"
          ? fieldName + " " + fieldValue
          : fieldName; // do not include personal data
      gtmTrack(
        "formInteraction",
        "Form Submissions",
        formName,
        eventLabel
      );
    }
  };

  if (form) {
    const el_inputs = form.querySelectorAll(
      "input[type=text], input[type=email], input[type=radio], input[type=checkbox], input[type=file], textarea, select"
    );

    el_inputs.forEach((input) => {
      if (input.type === "text" || input.type === "email" || input.checked) {
        onFieldInteract(input);
      }
    });
  }
};

export const gtmTrackFormErrorsOnSubmit = (formName, errors, errorMessages) => {
  const errorsAndMessages = Object.keys(errors).map(
    (err) => `${err} - ${errorMessages[err][errors[err][0]]}`
  );
  // Form Error Case B
  // Category: Form Error
  // Action: Fields
  // Label: [Error Message1, Error Message 2, Error Message 3, Error Message n]
  // *Fields are limited to 100 characters with the free version of GA4
  gtmTrack(
    "formError",
    "Validation Errors",
    formName,
    errorsAndMessages.join(", ")
  );
};

export const gtmTrackFormErrorsOnBlur = (formName, fieldName, errorMessage) => {
  // Form Error Case A
  // Category: Form Error
  // Action: [Form Name] - [Field Name]
  // Label: [Error Message]
  // *Fields are limited to 100 characters with the free version of GA4
  gtmTrack(
    "formError",
    "Validation Errors",
    formName,
    fieldName + "-" + errorMessage
  );
};

export const gtmTrackFormSuccess = (formName) => {
  // Form Success
  // Category: Form Submissions
  // Action: [Form Name]
  // Label: Success
  gtmTrack(
    "formSuccess",
    "Form Submissions",
    formName,
    "Success"
  );
};

export const exitModalTagging = (linkText, url) => {
  // Event: Exit Links
  // Category: External Links
  // Action: Close or Cancel
  // Label: linkText + target URL
  gtmTrack("Exit Links", "External Links", linkText, linkText + " " + url);
};
