exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-excessive-underarm-sweating-js": () => import("./../../../src/pages/excessive-underarm-sweating.js" /* webpackChunkName: "component---src-pages-excessive-underarm-sweating-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-get-sofdra-js": () => import("./../../../src/pages/get-sofdra.js" /* webpackChunkName: "component---src-pages-get-sofdra-js" */),
  "component---src-pages-how-to-use-js": () => import("./../../../src/pages/how-to-use.js" /* webpackChunkName: "component---src-pages-how-to-use-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */)
}

